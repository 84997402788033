
    var riot = require('riot')
    
riot.tag2('countdown', '<div class="days">{days} </div> <div class="hours">{hours} </div> <div class="minutes">{minutes} </div> <div class="seconds">{seconds} </div>', '', '', function(opts) {
this.on("mount", function() {
  if (opts.date) {
    this.timeleft = Math.round(((new Date(opts.date).getTime()) - (new Date().getTime())) / 1000);
    return this.updateTime();
  }
});

this.on("unmount", function() {
  return clearTimeout(this.timeout);
});

this.updateTime = (function(_this) {
  return function() {
    var timeleft;
    _this.timeleft -= 1;
    timeleft = _this.timeleft;
    _this.days = Math.floor(timeleft / 60 / 60 / 24);
    timeleft -= _this.days * 60 * 60 * 24;
    _this.hours = Math.floor(timeleft / 60 / 60);
    timeleft -= _this.hours * 60 * 60;
    _this.minutes = Math.floor(timeleft / 60);
    timeleft -= _this.minutes * 60;
    _this.seconds = timeleft;
    _this.update();
    return _this.timeout = setTimeout(function() {
      return _this.updateTime();
    }, 1000);
  };
})(this);
});
    
  