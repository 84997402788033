<script>
// src/components/Portal.svelte
import { onMount, onDestroy, afterUpdate } from 'svelte'
export let zindex = 2000
export let target = null
let ref
let portal
const doc = document

onMount(() => {
  if(!target){
    target = ref
    while(target.parentElement && target.parentElement.tagName!="BODY"){ target = target.parentElement }
  }
  portal = doc.createElement('div')
  portal.className = 'portal'
  target.appendChild(portal)
  portal.style.zIndex=zindex
  portal.appendChild(ref)
})

afterUpdate(()=>{
  portal.style.zIndex=zindex
})

onDestroy(() => {
  try{ target.removeChild(portal) }catch(err){}
})

</script>

<div bind:this={ref}>
  <slot></slot>
</div>

<style>

  :global(.portal){
    z-index:2000;
    position: fixed;
    padding:none;
  }
</style>
