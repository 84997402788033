
    var riot = require('riot')
    
riot.tag2('decline-event', '<dl class="dl-horizontal"> <dt>Event Name : </dt> <dd>{event.name}</dd> <dt>Start Date: </dt> <dd> <dateformat date="{new Date(event.start)}" format="dd MMMM yyyy"></dateformat> </dd> </dl> <div if="{feedbacktype==\'success\'}"> <div class="alert {\'alert-\'+feedbacktype}">Your invitation has been offically declined</div> <p>You can view your other registrations by <a members my-registrations class="btn btn-primary">clicking here</a></p> </div> <div if="{feedbacktype==\'danger\'}"> <div class="alert {\'alert-\'+feedbacktype}">Sorry there has been an error declining this invite. Please reclick the link in the email that brought you here, and try again</div> <p>If the problem persists <a href="/contact-us/">contact us here for help</a></p> </div> <div if="{!feedbacktype}"> <button onclick="{decline}" class="btn btn-primary">Decline Event Invitation </button> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  return xhr("GET", "/duocms/api/events/" + opts.eventid, {}, (function(_this) {
    return function(res) {
      if (res.status === 200) {
        _this.event = res.body.data;
        return _this.update();
      }
    };
  })(this));
});

this.decline = (function(_this) {
  return function() {
    return xhr("POST", "/duocms/api/events/declineconfirm/", {
      event_id: opts.eventid
    }, function(res) {
      if (res.status === 200) {
        return _this.update({
          feedbacktype: "success"
        });
      } else {
        return _this.update({
          feedbacktype: "danger"
        });
      }
    });
  };
})(this);
});
    
  