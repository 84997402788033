
    var riot = require('riot')
    
riot.tag2('registrations', '<div if="{view==\'loading\'}"> <div class="alert alert-info"> <h4><span class="fa fa-refresh fa-spin fa-fw"></span> Loading Data</h4> </div> </div> <div if="{view==\'list\'}"> <table class="table table-striped"> <thead> <tr> <th class="col-xs-4">Event Name</th> <th>Event Start </th> <th>Event End </th> <th>Draft</th> <th>Last Updated </th> <th> </th> </tr> </thead> <tbody> <tr each="{registrations}"> <td>{event.name}</td> <td> <dateformat date="{new Date(event.start)}" format="dd/MM/yyyy"></dateformat> </td> <td> <dateformat date="{new Date(event.end)}" format="dd/MM/yyyy"></dateformat> </td> <td class="text-center">{draft ? ⁗&check;⁗ : ⁗⁗}</td> <td> <dateformat date="{new Date(updated_at)}" format="HH:mm dd/MM/yyyy"></dateformat> </td> <td> <div class="btn-toolbar pull-right"><a href="{event.link}" show="{event.link}" class="btn btn-default btn-sm">View Event </a> <div onclick="{editEvent}" __disabled="{new Date(event.end)&lt;new Date()}" class="btn btn-primary btn-sm">{draft ? \'Edit Registration\' : \'View Registration\'}</div> </div> </td> </tr> </tbody> </table> </div> <div if="{view==\'details\'}"> <hr> <registration-details id="{id}" setview="{setView}" loadlist="{loadList}"></registration-details> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr");

this.on("mount", function() {
  if (window.location.search.match(/editmode=/)) {
    this.root.innerHTML = "<div class='alert alert-warning'>Registration list</div>";
  }
  return this.loadList();
});

this.loadList = (function(_this) {
  return function() {
    _this.update({
      view: "loading",
      id: ""
    });
    return xhr("get", "/duocms/api/registrations/my", null, function(res) {
      return _this.update({
        registrations: res.body.data,
        view: "list"
      });
    });
  };
})(this);

this.editEvent = (function(_this) {
  return function(e) {
    var reg;
    reg = _this.registrations.find(function(ev) {
      return ev.id === e.item.id;
    });
    if (event && new Date(reg.event.end) < new Date()) {
      return;
    }
    _this.id = e.item.id;
    return _this.update({
      view: "details"
    });
  };
})(this);

this.setView = (function(_this) {
  return function(view) {
    return _this.update({
      view: view
    });
  };
})(this);
});
    
  