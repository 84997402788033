window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/formdate'
require 'riot-kit/lib/confirm'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')

require './tags/loginbar.tag'
# require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
# require './tags/tags-input.tag'
# require './tags/comments_input.tag'
# require './tags/comments_output.tag'
require './tags/recaptcha.tag'
# require './tags/pricetag.tag'
require './libs/slideshow.coffee'
require './tags/registrations'
require './tags/registration-details'
require './tags/countdown'
require './tags/decline'
require './tags/event-feedback'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')