
    var riot = require('riot')
    
riot.tag2('event-feedback', '<form action="/duocms/api/events/feedback" method="post" class="form-horizontal"> <formstatic label="Event Name" value="{event.name}"></formstatic> <input type="hidden" name="Event" value="{event.name}"> <input type="hidden" name="_csrf" value="{window.csrf}"> <formselect label="What did you think of the venue?" name="venue_rating" options="{ratings}" default="Please Select" isrequired> </formselect> <formtextarea label="Other comment about the venue?" name="venue_comments"></formtextarea> <formselect label="What did you think of the presentations?" options="{ratings}" name="presentation_rating" default="Please Select" isrequired> </formselect> <formtextarea label="Any other comments about the presentations?" name="presentation_comments"></formtextarea> <p>If there is any other information you\'d like to share with us about the event, please feel free to add it below.</p> <formtextarea label="Other Feedback?" name="other_feeback"></formtextarea> <div class="row"> <div class="col-md-offset-4 col-md-8"> <div class="btn-toolbar"> <button class="btn btn-primary">Submit Feedback</button> </div> <p><br></p> </div> </div> </form>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.ratings = [
  {
    label: "Excellent",
    value: "5 - Excellent"
  }, {
    label: "Good",
    value: "4 - Good"
  }, {
    label: "Ok",
    value: "3 - Ok"
  }, {
    label: "Fair",
    value: "2 - Fair"
  }, {
    label: "Poor",
    value: "1 - Poor"
  }
];

console.log(this.ratings);

this.on("mount", function() {
  return xhr("GET", "/duocms/api/events/" + opts.eventid, {}, (function(_this) {
    return function(res) {
      if (res.status === 200) {
        _this.event = res.body.data;
        return _this.update();
      }
    };
  })(this));
});

this.decline = (function(_this) {
  return function() {
    return xhr("POST", "/duocms/api/events/declineconfirm/", {
      event_id: opts.eventid
    }, function(res) {
      if (res.status === 200) {
        return _this.update({
          feedbacktype: "success"
        });
      } else {
        return _this.update({
          feedbacktype: "danger"
        });
      }
    });
  };
})(this);
});
    
  