<script>
  export let size=""
  export let mode=""
  export let href=null
  export let elem=""
  export let type="button"

</script>

{#if href}
  <a role="button" {...$$props} href={href} elem={null} class={"sbtn "+(size ? size+" " : "")+(mode ? mode+" " : "")+($$props.class ? " "+$$props.class : "")}><slot></slot></a>
{:else}
  <button {...$$props} elem={null} bind:this={elem} type={type} on:click on:mousedown class={"sbtn "+(size ? size+" " : "")+(mode ? mode+" " : "")+($$props.class ? " "+$$props.class : "")}>
    <slot></slot>
  </button>
{/if}

<style>
  .sbtn{
    border:1px solid #ccc;
    position: relative;
    box-sizing: border-box;
    padding:6px 12px;
    background:#fff;
    font-size: 14px;
    text-decoration: none;
    color:#333;
    white-space: nowrap;
    display: inline-block;
    margin:var(--s-button-margins,4px);
    text-align: center;
    cursor: pointer;
    line-height:1.25;
    border-radius:var(--s-border-radius,4px);
    box-shadow:0 0 0px rgba(0,0,0,0.5),0 0px 0px rgba(0,0,0,0.1);
    transition:box-shadow 0.2s;
    line-height:20px;
    height:34px;
    vertical-align: middle;
  }

  /* .sbtn    :global(svg){ height:15px; margin:0 2px; display:inline-block;vertical-align: middle;}

  .sbtn.xs :global(svg){ height:10px;width:10px;}
  .sbtn.sm :global(svg){ height:12px;width:12px;}
  .sbtn.lg :global(svg){ height:21px;width:21px;} */

  .sbtn:disabled{
    opacity:0.5;
    cursor:not-allowed;
  }
  .sbtn:disabled:hover{
    box-shadow:0 0 2px rgba(0,0,0,0.1),0 0px 0px rgba(0,0,0,0.1);
  }
  .sbtn:hover{box-shadow:0 0 4px rgba(0,0,0,0.5),0 0px 0px rgba(0,0,0,0.2);} 
  .sbtn:focus{outline:0px;box-shadow:0 0 3px rgba(0,0,0,0.5)}
  .sbtn:active{ outline:0px; box-shadow:inset 0 4px 4px rgba(0,0,0,0.2);} 
  .xs{font-size:10px; padding:2px 7px;height:21px;line-height:12px;}
  .sm{font-size:12px; padding:0px 10px;height:26px;line-height:16px;}
  .lg{font-size:21px; padding:10px 20px;height:52px;line-height:30px;}
  .block{display:block;width:100%;}

  .primary,.info,.success,.warning,.danger,.dark{
    color:white;
    box-shadow:0 0 2px rgba(0,0,0,0.1),0 0px 0px rgba(0,0,0,0.1);
  }   
 
  .primary{border-color:#2299ee;background-color:#2299ee; background-color:var(--s-primary,#2299ee);border-color:var(--s-primary,#2299ee)}
  .info{border-color:#17a2b8;background-color:#17a2b8; background-color:var(--s-info,#17a2b8);border-color:var(--s-info,#17a2b8);}
  .success{border-color:#28a745;background-color:#28a745; background-color:var(--s-success,#28a745);border-color:var(--s-success,#28a745);}
  .warning{border-color:#ffc107;background-color:#ffc107; background-color:var(--s-warning,#ffc107);border-color:var(--s-warning,#ffc107);}
  .danger{border-color:#dc3545;background-color:#dc3545; background-color:var(--s-danger,#dc3545);border-color:var(--s-danger,#dc3545);}
  .dark{border-color:#343a40;background-color:#343a40; background-color:var(--s-dark,#343a40);border-color:var(--s-dark,#343a40);}
</style>